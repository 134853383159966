import { Box, Typography, makeStyles } from "@material-ui/core";
import { getData } from '../utils/FetchUtils';
import MultipleOptions from "./MulptipleOptions";
import { Fragment, useEffect, useRef, useState } from "react";
import ActionsWrapper from "./ActionsWrapper";
import useAbortableFetch from '../../hooks/useAbortableFetch';
import DeleteRevenueSplitModal from "./DeleteRevenueSplitModal";
import ManageRevenueSplitModal from "./ManageRevenueSplitModal";
import { formatNumber } from "../utils/NumberUtils";
import { TempBrandButton } from "../CoreComponents/BrandButton";
import { BrandAutocomplete, SongAutoComplete } from "../CoreComponents/BrandAutoComplete";
import { ButtonSize } from "../../constants/buttonConstants";
import { BrandTooltipWithIcon } from "../CoreComponents/BrandTooltip";
import { ExactWordTooltipMessage } from "../../constants/messageConstants";
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    pageMainHeader: {
        fontSize: '22px',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    totalRevenueWrapper: {
        fontSize: '18px/17px',
        fontWeight: 'bold',
        marginBottom: '30px'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '20px',
        alignItems: 'flex-start',
        '& .MuiButton-root': {
            textTransform: 'capitalize'
        }
    },
    mainWrapper: {
        marginTop: theme.spacing(3)
    },
    errorWrapper: {
        textAlign: 'center',
        color: theme.palette.primary.alert
    },
    filterWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(5),
    },
    autocompleteWrapper: {
        width: '325px',
        display: 'flex',
        flexDirection: 'row',
    },
    filterLabel: {
        color: theme.palette.text.lightYellow,
    },
    applyBtn: {
        width: '136px'
    },
    backButton: {
        marginLeft: 'auto',
        width: '6vw',
    }
}));

const RevenueSplits = () => {
    const history = useHistory();

    const [accountHolders, setAccountHolders] = useState([]);
    const [selectedAccountHolder, setSelectedAccountHolder] = useState(0);
    const [availableSongs, setAvailableSongs] = useState([]);
    const [selectedSong, setSelectedSong] = useState(0);
    const [song, setSong] = useState('');
    const [revenueSplits, setRevenueSplits] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectOnlyUnassigned, setSelectOnlyUnassigned] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showManageModal, setShowManageModal] = useState(false);
    const accountHolderRef = useRef(null);

    const handleShowDeleteModal = () => setShowDeleteModal(true);
    const handleHideDeleteModal = () => setShowDeleteModal(false);

    const handleShowManageModal = () => setShowManageModal(true);
    const handleHideManageModal = () => setShowManageModal(false);

    const styles = useStyles();
    const abortableFetch = useAbortableFetch();

    const handleApplyFilter = () => {
        fetchRevenueSplits();
        setSelectedItems([]);
        setSelectOnlyUnassigned(false);
    }

    const handleSelectAccountHolder = (acccountHolderId) => {
        resetRevenueSplitsData();
        setSelectedSong(0);

        setSelectedAccountHolder(acccountHolderId);
        accountHolderRef.current = accountHolders.find(a => a.id == acccountHolderId);

        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/uploaded-songs?accountHolderId=${acccountHolderId}`)
            .then(data => {
                setAvailableSongs(data.map(x => ({ ...x, name: `${x.artistName} - ${x.title}` })));
            })
            .catch(error => setErrorMessage(error.message));
    }

    const handleSelectSong = (songId) => {
        resetRevenueSplitsData();
        setSelectedSong(songId);
    }

    const fetchRevenueSplits = async () => {
        try {
            const { revenueSplits, song } = await abortableFetch('GET', `/api/revenue-split/${selectedSong}`, {
                query: {
                    accountHolderId: selectedAccountHolder,
                    selectOnlyUnassigned,
                    order: selectedOption?.order,
                    sortColumn: selectedOption?.sortColumn
                }
            });

            if (!selectOnlyUnassigned && revenueSplits.length === 0) {
                setErrorMessage('No reports attached.');
                return;
            }

            setSong(song);
            setSelectAllChecked(false);
            setRevenueSplits(revenueSplits);
            setErrorMessage('');
        } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
        }
    }

    const resetRevenueSplitsData = () => {
        setRevenueSplits([]);
        setSong('');
        setSelectAllChecked(false);
        setSelectOnlyUnassigned(false);
        setSelectedItems([]);
        setErrorMessage('');
    }

    useEffect(() => {
        getData(process.env.REACT_APP_SERVER_HOST + `/api/account-holder/my`)
            .then(data => {
                setAccountHolders(data);
            })
            .catch(error => setErrorMessage(error.message));
    }, []);

    useEffect(() => {
        if (song) fetchRevenueSplits();
    }, [selectOnlyUnassigned]);

    return (
        <Fragment>
            <Typography className={styles.pageMainHeader}>Revenue Split {song && `for ${song.artistName} - ${song.title}`}</Typography>
            <div className={styles.filterWrapper}>
                <div className={styles.filterLabel}>Account Holder</div>
                <div className={styles.autocompleteWrapper}>
                    <BrandAutocomplete
                        placeholder={'Select Account Holder'}
                        selectedAutoCompleteOption={selectedAccountHolder}
                        onSelectedAutoCompleteOption={handleSelectAccountHolder}
                        autoCompleteOptions={accountHolders}
                    />
                </div>
                <div className={styles.filterLabel}>Apply to Song</div>
                <div className={styles.autocompleteWrapper}>
                    <SongAutoComplete
                        placeholder={'Select Song'}
                        selectedAutoCompleteOption={selectedSong}
                        onSelectedAutoCompleteOption={handleSelectSong}
                        autoCompleteOptions={availableSongs}
                    />
                    <BrandTooltipWithIcon title={ExactWordTooltipMessage} /> 
                </div>
                <TempBrandButton size={ButtonSize.SMALL} capitalize={true} onClick={handleApplyFilter} disabled={!selectedAccountHolder || !selectedSong} className={styles.applyBtn}>
                    Apply
                </TempBrandButton>
                <TempBrandButton 
                    className={styles.backButton} 
                    capitalize={true} 
                    onClick={() => history.push('/portfolio')}>
                    Back
                </TempBrandButton>
            </div>
            {errorMessage && !song ? (
                <Typography className={styles.errorWrapper}>
                    {errorMessage}
                </Typography>
            ) : (
                <Fragment>
                    {song ? (
                        <Box className={styles.mainWrapper}>
                            {showDeleteModal && (
                                <DeleteRevenueSplitModal
                                    accountHolder={accountHolderRef.current}
                                    song={song}
                                    open={showDeleteModal}
                                    onClose={handleHideDeleteModal}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    fetchRevenueSplits={fetchRevenueSplits}
                                />
                            )}
                            {showManageModal && (
                                <ManageRevenueSplitModal
                                    song={song}
                                    open={showManageModal}
                                    onClose={handleHideManageModal}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    fetchRevenueSplits={fetchRevenueSplits}
                                />
                            )}
                            <Typography className={styles.totalRevenueWrapper}>
                                Total revenue by statements ${formatNumber(song.revenue)}
                            </Typography>
                            <Box className={styles.contentWrapper}>
                                <ActionsWrapper
                                    revenueSplits={revenueSplits}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    selectAllChecked={selectAllChecked}
                                    selectOnlyUnassigned={selectOnlyUnassigned}
                                    setSelectAllChecked={setSelectAllChecked}
                                    setSelectOnlyUnassigned={setSelectOnlyUnassigned}
                                    handleShowDeleteModal={handleShowDeleteModal}
                                    handleShowManageModal={handleShowManageModal}
                                    selectedOption={selectedOption}
                                    setSelectedOption={setSelectedOption}
                                    fetchRevenueSplits={fetchRevenueSplits}
                                />
                                <MultipleOptions
                                    data={revenueSplits}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                    setSelectAllChecked={setSelectAllChecked}
                                    handleShowManageModal={handleShowManageModal}
                                    accountHolder={accountHolderRef.current}
                                />
                            </Box>
                        </Box>
                    ) : null}
                </Fragment>
            )}
        </Fragment>
    )
}

export default RevenueSplits;