import React, { useState, useEffect, useCallback } from 'react';
import { getData } from '../utils/FetchUtils'
import { BrandHorizontalBarsChart, BrandVerticalBarsChart, BrandDoughnutChart } from '../CoreComponents/BrandChart'
import { BrandSelect, BrandMenuItem } from '../CoreComponents/BrandSelect'
import { BrandProgressBar } from '../CoreComponents/BrandLoader'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandLink } from '../CoreComponents/BrandLink';
import { roundToSecondDecimal } from '../Portfolio/NftPortfolio';
import EarningsChart from './EarningsChart';
import useAbortableFetch from '../../hooks/useAbortableFetch';
import { formatNumber } from '../utils/NumberUtils';
import { reportNames } from '../utils/ReportNamesUtils';

const useStyles = makeStyles((theme) => ({
    calculation: {
        marginBottom: theme.spacing(3.5),
        '& div:first-child': {
            font: 'normal normal normal 18px/37px Roboto'
        },
        '& div:last-child': {
            font: 'normal normal normal 25px/37px Roboto',
        },
    },
    songTitle: {
        font: 'normal normal bold 18px/36px Roboto',
        letterSpacing: '0px',
    },
    title: {
        marginBottom: theme.spacing(6),
        color: theme.palette.primary.main,
        font: 'normal normal normal 24px/34px Roboto',
        letterSpacing: 1.2
    },
    calculations: {
        width: theme.spacing(50)
    },
    component: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
        marginTop: theme.spacing(10),
        minHeight: '480px'
    },
    rightSide: {
        padding: theme.spacing(1.625),
        borderRadius: theme.spacing(0.625),
        background: theme.palette.primary.black,
        cursor: 'pointer'
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    charts: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: theme.spacing(15)
    },
    vertical: {
        height: '350px',
        display: 'inline-block',
        border: `1px solid ${theme.palette.primary.darkGrayNuance}`,
        marginRight: theme.spacing(10)
    },
    horizontal: {
        border: `1px solid ${theme.palette.primary.darkGrayNuance}`
    },
    barAndSelect: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        '& a:hover': {
            textDecoration: 'none'
        },
    },
    select: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '& .MuiInputBase-root': {
            minWidth: '215px',
            borderColor: theme.palette.primary.border
        },
        "& .MuiSelect-root": {
            letterSpacing: '0px',
            font: 'normal normal medium 18px/26px Roboto',
            fontSize: '18px'
        }
    },
    artistSelectLabel: {
        font: 'normal normal normal 20px/24px Roboto',
        letterSpacing: '1px',
        color: theme.palette.text.lightYellow
    },
    fourChartsHeadings: {
        display: 'flex',
        marginBottom: '10px',
        font: 'normal normal normal 18px/37px Roboto'
    },
    fourChartsHeadingsWihoutButton: {
        marginRight: '15px'
    },
    fourChartsSubheadings: {
        marginBottom: '15px',
        font: 'normal normal normal 14px/33px Roboto',
        color: theme.palette.text.lightGray
    },
    miniChartsComponent: {
        marginTop: theme.spacing(10),
    },
    halfPartMiniChartsComponent: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: '55px'
    },
    secondHalfPartMiniChartsComponent: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    fourChartsButtons: {
        color: 'black',
        fontSize: '9px',
        fontWeight: 'bold'
    },
    singleAccountHolderWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: theme.spacing(4),
        alignItems: 'center'
    },
    accountHolder: {
        marginLeft: theme.spacing(1),
        color: theme.palette.text.primary
    },
    singleAccountHolderLabel: {
        font: 'normal normal normal 14px/16px Roboto',
        color: theme.palette.text.lightYellow,
        letterSpacing: '1px',
    }
}));

const formatQuarters = (chartData, setSumRevenue) => {
    if (chartData.length > 0) {
        chartData.sort((a, b) => a.reportingPeriod - b.reportingPeriod);
        setSumRevenue({
            revenue: chartData.reduce((acc, curr) => acc + Number(curr.value), 0),
            startQ: 'Q' + chartData[0].reportingPeriod.toString().slice(4) + ' ' + chartData[0].reportingPeriod.toString().slice(0, 4),
            endQ: 'Q' + chartData[chartData.length - 1].reportingPeriod.toString().slice(4) + ' ' + chartData[chartData.length - 1].reportingPeriod.toString().slice(0, 4)
        })
    }
}

const defaultData = {
    "earningsDistribution": {
        "totalPortfolioValuation": 0,
        "totalEarnings": 0,
        "totalMasterEarnings": 0,
        "totalCompositionEarnings": 0,
        "avgEarningPerSong": 0,
        "data": [
            {
                "name": "Master Performance",
                "value": 0
            },
            {
                "name": "Label Distributor",
                "value": 0
            },
            {
                "name": "Publisher",
                "value": 0
            },
            {
                "name": "PRO",
                "value": 0
            }
        ]
    },
    "totalEarningPerYear": {
        "avgEarningPerYear": 0,
        "highestEarning": 0,
        "year": 0,
        "highestEarningSong": "N/A",
        "highestEarningSongEarnings": 0,
        "data": [
            {
                "year": 0,
                "value": 0
            },
        ]
    },
    "topEarningTracks": {
        "topThreeTotalEarningsSum": 0,
        "topThreeTotalEarningsPercentage": 0,
        "allTracks": 0,
        "data": [
            {
                "name": "N/A",
                "value": 0
            }
        ]
    },

};

const earningsChartsDefaultData = {
    revenue: 0,
    startQ: '',
    endQ: ''
}

export default function SongsDashboard() {
    const styles = useStyles();
    const [accountHolders, setAccountHolders] = useState([]);
    const selectedAccountHolder = useState(0);

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [sourceChartData, setSourceChartData] = useState([]);
    const [sumSourceRevenue, setSumSourceRevenue] = useState(earningsChartsDefaultData);

    const [territoryChartData, setTerritoryChartData] = useState([]);
    const [sumTerritoryRevenue, setSumTerritoryRevenue] = useState(earningsChartsDefaultData);

    const [companyChartData, setCompanyChartData] = useState([]);
    const [sumCompanyRevenue, setSumCompanyRevenue] = useState(earningsChartsDefaultData);

    const [reportTypeChartData, setReportTypeChartData] = useState([]);
    const [sumReportTypeRevenue, setSumReportTypeRevenue] = useState(earningsChartsDefaultData);

    const abortableFetch = useAbortableFetch();

    const [earningsDistribution, setEarningsDistribution] = useState({
        totalPortfolioValuation: 0,
        totalEarnings: 0,
        totalMasterEarnings: 0,
        totalCompositionEarnings: 0,
        avgEarningPerSong: 0,
        data: [],
    });
    const [totalEarningPerYear, setTotalEarningPerYear] = useState(
        {
            avgEarningPerYear: 0,
            highestEarning: 0,
            year: 0,
            highestEarningSong: '',
            highestEarningSongEarnings: 0,
            data: []
        });
    const [topEarningTracks, setTopEarningTracks] = useState({
        topThreeTotalEarningsSum: 0,
        topThreeTotalEarningsPercentage: 0,
        allTracks: 0,
        data: []
    });
    const [progress, setProgressData] = useState(94);
    const [pieChartColors] = useState(['#9A2A9D', '#F96C00', '#EDE107', '#03B56D']);

    useEffect(() => {
        abortableFetch('GET', '/api/account-holder/my')
        .then(data => {
            setAccountHolders(data)
        })
    }, []);

    const [loaders, setLoaders] = useState({
        isLoadingGeneral: false,
        isLoadingTop3Songs: false,
        isLoadingEarnings: false,
        isLoadingSourceChart: false,
        isLoadingTerritoryChart: false,
        isLoadingCompanyChart: false,
        isLoadingReportTypeChart: false
    });

    const fetchDashboardData = useCallback(() => {
        const fetchData = async () => {
            try {
                setLoaders({
                    isLoadingGeneral: true,
                    isLoadingTop3Songs: true,
                    isLoadingSourceChart: true,
                    isLoadingEarnings: true,
                    isLoadingTerritoryChart: true,
                    isLoadingCompanyChart: true,
                    isLoadingReportTypeChart: true
                });
                
                setTopEarningTracks(defaultData.topEarningTracks);
                setTotalEarningPerYear(defaultData.totalEarningPerYear);
                setEarningsDistribution(defaultData.earningsDistribution);

                const accountHolder = selectedAccountHolder[0] || '' ;
                const query = { query: { accountHolder }};

                const [top3SongsData, songsData, miniChartsData, territoryData, companyData, reportTypeData] = 
                    await Promise.all([
                        abortableFetch('GET', '/api/elastic/top-earning-songs', query),
                        abortableFetch('GET', '/api/elastic/general', query),
                        abortableFetch('GET', '/api/elastic/top-play-sources', query),
                        abortableFetch('GET', '/api/elastic/top-countries', query),
                        abortableFetch('GET', '/api/elastic/top-reporting-companies', query), 
                        abortableFetch('GET', '/api/elastic/top-report-types', query),
                    ]);

                top3SongsData.topEarningTracks.data.forEach((x, i) => {
                    x.value = roundToSecondDecimal(x.value || 0);
                    x.color = pieChartColors[i % pieChartColors.length];
                });
                reportTypeData.reportTypesWithRevenues.forEach(report => {
                    const correspondingName = reportNames.find(name => name.id === report.reportType);
                    if (correspondingName) {
                        report.reportType = correspondingName.name;
                    }
                });

                songsData.earningsDistribution.data
                    .forEach((x, i) => {
                        x.value = roundToSecondDecimal(x.value)
                    });

                setTopEarningTracks(top3SongsData.topEarningTracks);
                setTotalRevenue(top3SongsData.songsTotalEarnings);
                setEarningsDistribution(songsData.earningsDistribution);

                setSourceChartData(miniChartsData.playSourcesWithRevenues);

                setTerritoryChartData(territoryData.countriesWithRevenues);
                setCompanyChartData(companyData.reportingCompaniesWithRevenues);
                setReportTypeChartData(reportTypeData.reportTypesWithRevenues);
            } catch (error) {
                console.log(error.message);

                setTopEarningTracks(defaultData.topEarningTracks);
                setEarningsDistribution(defaultData.earningsDistribution);
            } finally {
                setLoaders({
                    isLoadingGeneral: false,
                    isLoadingTop3Songs: false,
                    isLoadingSourceChart: false,
                    isLoadingTerritoryChart: false,
                    isLoadingCompanyChart: false,
                    isLoadingReportTypeChart: false
                });
            }
        }

        const fetchYearlyEarnings = async () => {
            try {
                const accountHolder = selectedAccountHolder[0] || '' ;
                const query = { query: { accountHolder }};

                const yearData = await abortableFetch('GET', '/api/elastic/yearly', query);
                setTotalEarningPerYear(yearData.totalEarningPerYear);
            } catch (error) {
                console.log(error.message);
                setTotalEarningPerYear(defaultData.totalEarningPerYear);
            } finally {
                setLoaders((prev) => ({
                    ...prev,
                    isLoadingEarnings: false
                }))
            }
        }

        fetchData();
        fetchYearlyEarnings();
    }, [selectedAccountHolder[0]]);

    useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    useEffect(() => {
        if (accountHolders.length === 1) {
            selectedAccountHolder[1](accountHolders[0].id)
        }
    }, [accountHolders])

    useEffect(() => {
        formatQuarters(sourceChartData, setSumSourceRevenue);
        formatQuarters(territoryChartData, setSumTerritoryRevenue);
        formatQuarters(reportTypeChartData, setSumReportTypeRevenue);
        formatQuarters(companyChartData, setSumCompanyRevenue);

    }, [sourceChartData, territoryChartData, reportTypeChartData, companyChartData])

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.charts}>
                    <div className={styles.barAndSelect}>
                        <div className={styles.select}>
                            { 
                            accountHolders && accountHolders.length < 2 ?
                                (
                                    <div className={styles.singleAccountHolderWrapper}>
                                        <div className={styles.singleAccountHolderLabel}>Account Holder: </div>
                                        <span className={styles.accountHolder}>
                                            {accountHolders[0]?.name ? accountHolders[0]?.name : 'N/A'}
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <div className={styles.accountHolderLabel}>Account Holder</div>
                                        <BrandSelect $value={selectedAccountHolder}>
                                            <BrandMenuItem value={0}>All</BrandMenuItem>
                                            {accountHolders.map(x => (
                                                <BrandMenuItem key={x.id} value={x.id}>
                                                    {x.name}
                                                </BrandMenuItem>
                                            ))}
                                        </BrandSelect>
                                    </>
                                ) }
                        </div>
                        {
                            process.env.REACT_APP_ENABLE_REVIEW_PAGE_TAB === 'true' ?
                                <BrandLink to='/review-page' className={styles.rightSide}>
                                    <BrandProgressBar progress={progress} />
                                </BrandLink>
                                :
                                null
                        }
                    </div>
                    <div className={styles.component}>
                        <div className={styles.calculations}>
                            <div className={styles.title}>General</div>
                            <div className={styles.calculation}>
                                <div>Total Earnings</div>
                                <div>${formatNumber(earningsDistribution.totalEarnings)}</div>
                            </div>
                            <div className={styles.calculation}>
                                <div>Total Master Earnings</div>
                                <div>${formatNumber(earningsDistribution.totalMasterEarnings)}</div>
                            </div>
                            <div className={styles.calculation}>
                                <div>Total Composition Earnings</div>
                                <div>${formatNumber(earningsDistribution.totalCompositionEarnings)}</div>
                            </div>
                            <div className={styles.calculation}>
                                <div>Average Earning per Song:</div>
                                <div>${formatNumber(earningsDistribution.avgEarningPerSong)}</div>
                            </div>
                        </div>
                        <hr className={styles.vertical} />
                        <BrandHorizontalBarsChart
                            isLoading={loaders.isLoadingGeneral}
                            data={earningsDistribution.data}
                            tooltipLabel='Earnings'
                            width='753px'
                            color='#1DAC9D'
                            height='360px'
                        />
                    </div>
                    <hr className={styles.horizontal} />


                    <div className={styles.component}>
                        <div className={styles.calculations}>
                            <div className={styles.title}>Top 3 Earning Tracks</div>
                            <div className={styles.calculation}>
                                <div>Top 3 Total Earnings</div>
                                <div>${formatNumber(topEarningTracks.topThreeTotalEarningsSum)}</div>
                            </div>
                            <div className={styles.calculation}>
                                <div>Top 3 Portfolio %</div>
                                <div>{topEarningTracks.topThreeTotalEarningsPercentage}%</div>
                            </div>
                            <div className={styles.calculation}>
                                <div>All Tracks</div>
                                <div>{topEarningTracks.allTracks}</div>
                            </div>
                        </div>
                        <hr className={styles.vertical} />
                        <BrandDoughnutChart
                            isLoading={loaders.isLoadingTop3Songs}
                            data={topEarningTracks.data}
                            hasLegend={true}
                            width='753px'
                            height='420px'
                        />
                    </div>
                    <hr className={styles.horizontal} />

                    <div className={styles.component}>
                        <div className={styles.calculations}>
                            <div className={styles.title}>Earnings per Year:</div>
                            <div className={styles.calculation}>
                                <div>Average Earnings per Year</div>
                                <div>${formatNumber(totalEarningPerYear.avgEarningPerYear)}</div>
                            </div>
                            <div className={styles.calculation}>
                                <div>Highest Earning Year {totalEarningPerYear.year}</div>
                                <div>${formatNumber(totalEarningPerYear.highestEarning)}</div>
                            </div>
                            <div className={styles.calculation}>
                                <div>Highest Earning Song for {totalEarningPerYear.year}</div>
                                <div className={styles.songTitle}>{totalEarningPerYear.highestEarningSong}</div>
                                <div>${formatNumber(totalEarningPerYear.highestEarningSongEarnings)}</div>
                            </div>
                        </div>
                        <hr className={styles.vertical} />
                        <BrandVerticalBarsChart
                            isLoading={loaders.isLoadingEarnings}
                            data={totalEarningPerYear.data}
                            tooltipLabel='Earnings'
                            width='753px'
                            color='#3DA1D7'
                            height='360px'
                        />
                    </div>
                    <hr className={styles.horizontal} />

                    <div className={styles.miniChartsComponent}>
                        <div className={styles.title}>Revenue TOP Breakdowns</div>
                        <div>
                            <div className={styles.halfPartMiniChartsComponent}>
                                <EarningsChart
                                    loadingChart={loaders.isLoadingSourceChart}
                                    chartData={sourceChartData}
                                    sumRevenue={sumSourceRevenue}
                                    totalRevenue={totalRevenue}
                                    chartHeader='TOP sources by revenue'
                                    dataType='playSource'
                                />
                                <EarningsChart
                                    loadingChart={loaders.isLoadingTerritoryChart}
                                    chartData={territoryChartData}
                                    sumRevenue={sumTerritoryRevenue}
                                    totalRevenue={totalRevenue}
                                    chartHeader='TOP countries by revenue'
                                    dataType='country'
                                />
                            </div>
                            <div className={styles.secondHalfPartMiniChartsComponent}>
                                <EarningsChart
                                    loadingChart={loaders.isLoadingReportTypeChart}
                                    chartData={reportTypeChartData}
                                    sumRevenue={sumReportTypeRevenue}
                                    totalRevenue={totalRevenue}
                                    chartHeader='TOP report types by revenue'
                                    dataType='reportType'
                                />
                                <EarningsChart
                                    loadingChart={loaders.isLoadingCompanyChart}
                                    chartData={companyChartData}
                                    sumRevenue={sumCompanyRevenue}
                                    totalRevenue={totalRevenue}
                                    chartHeader='TOP companies by revenue'
                                    dataType='reportingCompany'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}